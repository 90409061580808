import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Preloader
const Preloader = React.lazy(() => import('./components/layouts/Preloader'));
// Dashboard
const Home = React.lazy(() => import('./components/pages/Home'));
// Add product
const Addproduct = React.lazy(() => import('./components/pages/product/Addproduct'));
// Product Page
const Productlist = React.lazy(() => import('./components/pages/product/Productlist'));
// Coupons
const ManageCoupons = React.lazy(() => import('./components/pages/coupon/Managemant'));
const ReportCoupons = React.lazy(() => import('./components/pages/coupon/Reports'));
const NewPayment = React.lazy(() => import('./components/pages/payment/New'));
const ManagePayment = React.lazy(() => import('./components/pages/payment/Manage'));
const ManagePartners = React.lazy(() => import('./components/pages/partners/Managemant'));
const PartnersReceipts = React.lazy(() => import('./components/pages/partners/Receipts'));
const PartnersManualValues = React.lazy(() => import('./components/pages/partners/Manual-values'));
const Setup = React.lazy(() => import('./components/pages/setup/Setup'));

const Defaultlogin = React.lazy(() => import('./components/pages/prebuilt-pages/Login'));

export default function App() {
  return (
    <Router basename={'/'}>
      <Suspense fallback={<div></div>}>
        <Preloader />
        <Switch>
          {/* Dashboard */}
          <Route exact path="/" component={Home} />

          {/* Product Page */}
          <Route path="/product/product-list" component={Productlist} />

          {/* Add product */}
          <Route path="/product/add-product" component={Addproduct} />

          {/* Coupons */}
          <Route path="/coupon/manage" component={ManageCoupons} />
          <Route path="/coupon/reports" component={ReportCoupons} />

          <Route path="/payment/new" component={NewPayment} />
          <Route path="/payment/view" component={ManagePayment} />

          <Route path="/partners/manage" component={ManagePartners} />
          <Route path="/partners/receipts" component={PartnersReceipts} />
          <Route path="/partners/manual-values" component={PartnersManualValues} />

          <Route path="/setup" component={Setup} />

          <Route path="/login" component={Defaultlogin} />
          <Route path="/" component={Home} />
        </Switch>
      </Suspense>
    </Router>
  );
}
